<template>
    <div class="pt-16 px-2 ">
        <h1 class="text-red text-lg mb-6">
            Edit Coupon
        </h1>
      <strong>Created At:</strong> {{showCreatedAt()}}
      <br>
      <strong>Last Updated:</strong> {{showUpdatedAt()}}
        <div class="" v-if="coupon">
            <SlideoutTabs default_width="half" :model="{id:coupon.id,type:'Coupon'}" :contents="tab_content"  orientation="top-0 bottom-0 right-0" position="fixed">
            </SlideoutTabs>
            <FormTemplate @response="formResponse" button="Save" method="post" action="/coupon" :formdata="formData">
                <TextInput type="text" label="Code" v-model="coupon.code" :required="false" ></TextInput>
                <SelectInput  label="Type" v-model="coupon.type" :options="[{label:'Percent',value:'percent'},{label:'Flat',value:'flat'}]" :required="false" ></SelectInput>
                <TextInput type="text" label="Discount" v-model="coupon.discount" :required="false" ></TextInput>
                <SelectInput  label="Initial Only" v-model="coupon.initial_only" :options="[{label:'Yes',value:1},{label:'No',value:0}]" :required="false" ></SelectInput>
                <SelectInput label="Limit Usage" v-model="coupon.limit_usage" :options="[{label:'Yes',value:'on'},{label:'No',value:'no'}]" :required="false" ></SelectInput>
                <TextInput type="number" label="Limit" v-model="coupon.limit" :required="false" ></TextInput>
                <SearchSelectInput @item:removed="productRemoved" @item:selected="productSelected" :params="productSearchParams" :multiple="true" label="Products" v-model="coupon.products" :optiondisplay="ProductDisplay"></SearchSelectInput>
            </FormTemplate>
        </div>
    </div>
</template>

<script>
import Axios from 'axios'
import Notes from '../../../components/dashboard/Notes'

export default {
    name: 'ViewCoupon',
    data: () => ({
        coupon: false,
        productSearchParams: {
            'model': 'Product',
            'fields': ['name','price'],
            'action': '/search/',
        },
        tab_content: {
            'notes': {
                label: 'Notes',
                icon: '/assets/notes.png',
                component: Notes,
                top: '200px',
            },
        }
    }),
    computed: {
        formData() {
            return {
                'coupon':this.coupon,
            }
        },
    },
    props: [],
    methods: {
        productSelected(evt) {
            let index = this.coupon.products.findIndex((product)=>{return product.id == evt.item.id})
            if(index < 0) this.coupon.products.push(evt.item);
        },
        productRemoved(evt) {
            this.coupon.products.splice(evt.index,1);
        },
        formResponse(re) {
            if(re.data.status == 'OK') {
                this.$notify({
                    title: 'Coupon updated',
                    text: '',
                    type: 'success',
                });
            }
            console.log(re);
        },
        ProductDisplay(item) {
            return item.id + ' - ' + item.name + ' - $' + item.price;
        },
        getCoupon(coupon_id) {
            let that = this;
            Axios.get(`/coupon/${coupon_id}`).then(re=>{
                that.coupon = re.data.coupon;
            }).catch(err=>{
                if(err.response.status == 403) {
                    that.$notify({
                        title: 'You are not allowed to do this...',
                        text: 'Get lost',
                        type: 'error',
                    });
                }else{
                    this.$notify({
                        title: 'Whoops something went wrong.',
                        text: err.message,
                        type: 'error',
                    });
                }
            });
        },
      showCreatedAt(){
        let createdDate = new Date(this.coupon.created_at);

        return createdDate.toLocaleDateString();
      },
      showUpdatedAt(){
        let createdDate = new Date(this.coupon.updated_at);

        return createdDate.toLocaleDateString();
      },
    },
    watch: {
    },
    created() {
        this.getCoupon(this.$route.params.coupon_id);
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
