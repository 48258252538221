<template>
<div class="container pt-16 px-2 ">
    <div class="flex">
        <h1 class="text-red text-lg mb-6">
            Coupons
        </h1>
        <LinkTemplate :to="{path:'/coupons/create'}" class="my-6 ml-6">
            Add New Coupon
        </LinkTemplate>
    </div>
    <div class="">
        <TextInput @input="maybeSearch" type="text" label="Search" v-model="search" :required="false" ></TextInput>
    </div>
    <TableTemplate v-if="loading == false"  @item:deleted="ItemDeleted" :candelete="false" canview="/coupons/" :columns="columns" :items="coupons"></TableTemplate>
    <img v-else class="m-auto my-4" style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading search submission">
</div>
</template>

<script>
import TableTemplate from '../../../components/dashboard/Table'
import Axios from 'axios'
export default {
    name: 'List',
    data: () => ({
        search: '',
        search_timeout: false,
        columns:[
            {
                id:'id',
                label:'ID',
            },
            {
                id:'code',
                label:'Code',
            },
            {
                id:'type',
                label:'type',
            },
            {
                id:'discount',
                label:'Discount',
            },
            {
                id:'status',
                label:'Status',
                custom: 'getStatus',
            },
        ],
        coupons: [],
        loading: false,
    }),
    computed: {
    },
    props: [],
    methods: {
        ItemDeleted(evt) {
            let that = this
            Axios.get(`/coupon/remove/${evt.item.id}`).then(()=>{
                that.coupons.splice(evt.index,1)
                this.$notify({
                    title: 'Coupon Deleted',
                    text: '',
                    type: 'success',
                });
            }).catch(err=>{
                if(err.response.status == 403) {
                    that.$notify({
                        title: 'You are not allowed to do this...',
                        text: 'Get lost',
                        type: 'error',
                    });
                }else{
                    this.$notify({
                        title: 'Whoops something went wrong.',
                        text: err.message,
                        type: 'error',
                    });
                }
            })
        },
        doSearch() {
            this.loading = true;
            let that = this;
            Axios.post('/search',{
                search: that.search,
                params: {
                    'model': 'Coupon',
                    'with': ['products'],
                    'fields': ['code','discount'],
                },
            }).then(re=>{
                that.loading = false;
                that.coupons = re.data.items
            }).catch(err=>{
                console.log(err);
                if(err.response.status == 403) {
                    that.$notify({
                        title: 'You are not allowed to do this...',
                        text: 'Get lost',
                        type: 'error',
                    });
                }else{
                    this.$notify({
                        title: 'Whoops something went wrong.',
                        text: err.message,
                        type: 'error',
                    });
                }
            });
        },
        maybeSearch() {
            if(this.search_timeout) clearTimeout(this.search_timeout);
            let that = this;
            if(!that.search || !that.search.length){
                that.getCoupons();
                return;
            }
            this.search_timeout = setTimeout(function(){ that.doSearch() }, 1000);
        },
        getStatus(coupon) {
            if(coupon.products.length) return 'Active';
            return 'Inactive';
        },
        getCoupons() {
            let that = this;
            Axios.get('/coupons').then(re=>{
                that.coupons = re.data.coupons;
            }).catch(err=>{
                if(err.response.status == 403) {
                    that.$notify({
                        title: 'You are not allowed to do this...',
                        text: 'Get lost',
                        type: 'error',
                    });
                }else{
                    this.$notify({
                        title: 'Whoops something went wrong.',
                        text: err.message,
                        type: 'error',
                    });
                }
            });
        },
    },
    watch: {
    },
    created() {
        this.getCoupons();
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
        TableTemplate,
    },
}
</script>
<style scoped>
</style>
