<template>
    <div class="pt-16 px-2 ">
        <h1 class="text-red text-lg mb-6">
            Create Coupon
        </h1>
        <div class="" v-if="coupon">
            <FormTemplate @response="formResponse" button="Create" method="post" action="/coupon/create" :formdata="formData">
                <TextInput type="text" label="Code" v-model="coupon.code" :required="false" ></TextInput>
                <SelectInput  label="Type" v-model="coupon.type" :options="[{label:'Percent',value:'percent'},{label:'Flat',value:'flat'}]" :required="false" ></SelectInput>
                <TextInput type="text" label="Discount" v-model="coupon.discount" :required="false" ></TextInput>
                <SelectInput  label="Initial Only" v-model="coupon.initial_only" :options="[{label:'Yes',value:1},{label:'No',value:0}]" :required="false" ></SelectInput>
                <SelectInput label="Limit Usage" v-model="coupon.limit_usage" :options="[{label:'Yes',value:'on'},{label:'No',value:'no'}]" :required="false" ></SelectInput>
                <TextInput type="number" label="Limit" v-model="coupon.limit" :required="false" ></TextInput>
                <SearchSelectInput @item:removed="productRemoved" @item:selected="productSelected" :params="productSearchParams" :multiple="true" label="Products" v-model="coupon.products" :optiondisplay="ProductDisplay"></SearchSelectInput>
            </FormTemplate>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ViewCoupon',
    data: () => ({
        coupon: {
            code: '',
            type: 'flat',
            discount: '',
            initial_only: 0,
            limit_usage: 'no',
            limit: '',
            products: [],
        },
        productSearchParams: {
            'model': 'Product',
            'fields': ['name','price'],
            'action': '/search/',
        },
    }),
    computed: {
        formData() {
            return {
                'coupon':this.coupon,
            }
        },
    },
    props: [],
    methods: {
        productSelected(evt) {
            let index = this.coupon.products.findIndex((product)=>{return product.id == evt.item.id})
            if(index < 0) this.coupon.products.push(evt.item);
        },
        productRemoved(evt) {
            this.coupon.products.splice(evt.index,1);
        },
        formResponse(re) {
            if(re.data.status == 'OK') {
                this.$notify({
                    title: 'Coupon Created',
                    text: '',
                    type: 'success',
                });
                this.$router.push({path:'/coupons'})
            }
            console.log(re);
        },
        ProductDisplay(item) {
            return item.id + ' - ' + item.name + ' - $' + item.price;
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
